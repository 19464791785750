/* eslint-disable require-jsdoc */
import React from 'react';
import Loading from '../components/Loading';
class Home extends React.Component {
  // eslint-disable-next-line no-useless-constructor
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <>

        <div className='mainfeed' style={{
          paddingLeft: 5,
          paddingRight: 5,
        }}>
          <h1>Melan home screen<Loading /> </h1>
        </div>

        <div className='mainfeed' style={{
          paddingLeft: 5,
          paddingRight: 5,
        }}>
        </div>

      </>
    );
  }
}

export default Home;

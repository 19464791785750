/* eslint-disable require-jsdoc */
import {
  BrowserRouter,
  Route, Routes,
} from 'react-router-dom';
import React from 'react';
// Top level components
import MainHeader from './components/Header';
import Leftnav from './components/LeftNavi';
// Import pages
import Home from './pages/Home';
import Login from './pages/Login';
import Logout from './pages/Logout';
import Signup from './pages/Signup';
import ChatNav from './components/ChatNavi';
import './App.css';


function App() {
  return (
    <div className='grid-container'>
      <div className='item1'>
        <MainHeader />
      </div>
      <div className='item2'>
        <Leftnav />
      </div>
      <div className='item3'>
        <BrowserRouter>
          <Routes>
            <Route path="/login" element={<Login />} />
            <Route path="/logout" element={<Logout />} />
            <Route path="/signup" element={<Signup />} />
            <Route path="/" element={<Home />} />
          </Routes>
        </BrowserRouter>
      </div>
      <div className='item4'>
        <ChatNav />
      </div>
    </div>
  );
};

export default App;

/* eslint-disable require-jsdoc */
import React, {Component} from 'react';
import ListItemText from '@mui/material/ListItemText';
// import ListItemAvatar from '@mui/material/ListItemAvatar';
import Typography from '@mui/material/Typography';
// import Avatar from '@mui/material/Avatar';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';


class Leftnav extends Component {
  render() {
    return (
      <List sx={{width: '100%'}}>
        <ListItem alignItems="flex-start">
          <ListItemText
            secondary={
              <React.Fragment>
                <Typography
                  component="span"
                  variant="h5"
                  color="text.primary"
                >
              Home
                </Typography>
              </React.Fragment>
            }
          />
        </ListItem>
        <ListItem alignItems="flex-start">
          <ListItemText
            secondary={
              <React.Fragment>
                <Typography
                  sx={{display: 'inline'}}
                  component="span"
                  variant="h5"
                  color="text.primary"
                >
                Trending
                </Typography>
              </React.Fragment>
            }
          />
        </ListItem>
        <ListItem alignItems="flex-start">
          <ListItemText
            secondary={
              <React.Fragment>
                <Typography
                  sx={{display: 'inline'}}
                  component="span"
                  variant="h5"
                  color="text.primary"
                >
                Notifications
                </Typography>
              </React.Fragment>
            }
          />
        </ListItem>
        <ListItem alignItems="flex-start">
          <ListItemText
            secondary={
              <React.Fragment>
                <Typography
                  sx={{display: 'inline'}}
                  component="span"
                  variant="h5"
                  color="text.primary"
                >
                Settings
                </Typography>
              </React.Fragment>
            }
          />
        </ListItem>
      </List>
    );
  }
}

export default Leftnav;

/* eslint-disable require-jsdoc */
import React from 'react';

class logout extends React.Component {
  // eslint-disable-next-line no-useless-constructor
  constructor(props) {
    super(props);
  }

  render() {
    return ('logout');
  }
}

export default logout;

import axios from 'axios';
// import MELAN_SERVICES from '../../constants/urlconstants';

const signupserv = async (signupdata) => {
  const request = {
    method: 'post',
    url: `http://localhost:5001/melan-10eef/us-central1/module-melanservices/signup`,
    body: {
      signupdata,
    },
  };

  axios(request)
      .then(function(response) {
        return response;
      })
      .catch(function(error) {
        return error;
      });
};

// const login = async (req, res) => {
//   await service.loginMelan(req, res);
//   return res;
// };

// const logout = async (req, res) => {
//   await service.logoutMelan(req, res);
//   return res;
// };

// module.exports = {
//   signupserv,
//   login,
//   logout,
// };

export default signupserv;
